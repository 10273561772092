import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import map from '../images/map.png'


const contactPage = () => (
  <Layout>
    <div className="jumbotron contact">
        <h1>Contact</h1>
        <p></p>
    </div>
    <div>
        <div className="row contact-page">
            <div className="col-md-6">
              <a href="https://www.google.com/maps/dir//pokez+san+diego/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x80d9535f4aa75a17:0xcb6ff83be8f6e680?sa=X&ved=2ahUKEwjOvdKdpN3qAhXmIjQIHW46CR8Q9RcwC3oECBUQEQ" target="_blank" rel="noreferrer">
                <img src={map} style={{width: '100%'}} alt="map" />
              </a>
            </div>
            <div className="col-md-6">
                <h2>Come by!</h2>
            <h3>Address</h3>
            <p>947 E Street <br/>
            Downtown San Diego, CA <br/>
            (619) 702-7160</p>

            <h3>Hours</h3>
            <p>Mon-Sun: 9AM-10PM   </p> 
                </div>
        </div>
    </div>
  </Layout>
)

export default contactPage;